import React from 'react';

import { Link } from 'src/app/link';
import { InlineList } from 'src/components/molecules/InlineList';
import { Section } from 'src/components/molecules/Section';
import { FixedImg } from 'src/embeds/image/image-dato';

export default function LegacySection({ callouts, customData }) {
  customData = JSON.parse(customData);

  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsLogoBlock');

  return (
    <Section {...customData?.flags}>
      {!!callouts.length && (
        <InlineList centered spacious style={{ marginTop: '2rem' }} {...customData?.listProps}>
          {callouts.map(({ media, url, customData }, i) => {
            customData = JSON.parse(customData);

            const Wrapper = url ? WithLink : WithoutLink;

            return (
              <li key={i}>
                <Wrapper href={url}>
                  <FixedImg
                    constrain
                    width={Math.round((media.width / media.height) * 30)}
                    height={30}
                    data={media}
                    style={{
                      ...(customData?.secondary
                        ? {
                            filter: `brightness(0)`,
                            opacity: 0.4,
                          }
                        : {}),
                    }}
                    {...customData?.flags}
                  />
                </Wrapper>
              </li>
            );
          })}
        </InlineList>
      )}
    </Section>
  );
}

const WithLink = ({ children, href }) => <Link href={href}>{children}</Link>;
const WithoutLink = ({ children }) => children;
